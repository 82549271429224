import Button from '../../components/button'
import Callout from '../../components/callout'
import Page, { Header } from '../../components/page'
import Seo from '../../components/seo'

const WorkCatchAll = () => (
  <>
    <Seo title="Work" />
    <Page container>
      <Header
        title="Work not found"
        subtitle="Couldn't find work you're looking for"
      />
      <Button variant="primary" link={{ to: '/work' }}>
        View all work
      </Button>
    </Page>
    <Callout />
  </>
)

export default WorkCatchAll
